"use client";

import {
  BlogCard,
  Button,
  CareersCard,
  Chip,
  Column,
  EmptyState,
  EventsCard,
  Flex,
  FormField,
  Heading,
  Icon,
  Loader,
  NewsCard,
  OffCanvas,
  ProductCard,
  Row,
  Separator,
  Slider,
  useMediaMatch,
} from "oddsgate-ds";
import { Field, Form, Formik, useFormik } from "formik";
import { IArchiveGrid, IFilters, IPost, Taxonomy, TaxonomyTerms } from "@/lib/wordpress/_types/archiveGrid";
import React, {
  ChangeEvent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import PaginationStepper from "@/components/common/PaginationStepper/PaginationStepper.component";
import { WpClientRequest } from "@/lib/wordpress/wpClientRequest";
import { formatDate } from "@/lib/helpers/dateFormate";
import { styled } from "styled-components";
import { useGlobalContext } from "@/lib/globalContext";

const StyledFilter = styled(Chip)`
  position: relative;
  
  & input{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0;
    margin: 0;
    cursor:pointer;
  }
`;

export default function BlockArchiveGrid({ attrs }: IArchiveGrid) {
  const {
    title,
    variant,
    post_type,
    component,
    per_line = 4,
    per_page,
    showFilters,
    showPagination,
    slider,
    sliderMobile,
    anchor,
    grid,
    className,
  } = attrs || {};
  const colSize = 12 / per_line;

  const isMobile = useMediaMatch();

  const [loading, setLoading] = useState<boolean | null>(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState<IFilters | null>();

  const [filteredPosts, setFilteredPosts] = useState<
    IPost[] | undefined
  >(grid?.items);

  const [totalPosts, setTotalPosts] = useState<
    number | undefined
  >(grid?.total || 0);

  const state = useGlobalContext();

  const { lang } = state;

  let activeFilters: string[] = [];
  let displayFilters: TaxonomyTerms[] = [];

  switch (component) {
    case "BlogCard":
      displayFilters = grid && grid.categories && (grid.categories as any).filter((x: any) => x.name === "category")[0]?.items;

      activeFilters = ["category"];
      break;
  }

  useEffect(() => {
    if (grid && !filters) {
      setFilteredPosts(grid.items);
      setTotalPosts(grid?.total);
    }

    if (post_type && filters) {
      setLoading(true);
      WpClientRequest({
        post_type,
        variables: {
          ...filters,
          limit: per_page,
          lang,
        },
      }).then((res) => {
        setFilteredPosts(res.items);
        setTotalPosts(res.total);
        setLoading(false);
      });
    }
  }, [filters, post_type, lang, grid]);

  const cachedContent = useMemo(() => {
    if (filteredPosts && filteredPosts.length !== 0) {
      return filteredPosts.map((post, i: number) => {
        if (!post) return false;
        const category = post?.categories as unknown as Taxonomy[] || [];
        const categoryName = category && Object.keys(category).length > 0 ? category[0]?.items[0]?.name : "";

        const imageElement = (
          <DisplayImage
            url={post?.image as string}
            alt={post?.title ? (post?.title as string) : ""}
            fill={true}
          />
        );

        switch (component) {
          case "BlogCard":
            return (

              <div key={`blogCard-${i}`} data-cursor={slider ? "drag" : ""}>
                <BlogCard
                  variant={variant}
                  date={formatDate(post?.date as string, "DATE_SHORT")}
                  category={categoryName}
                  imageElement={imageElement}
                  title={post.title as string}
                  description={post.excerpt as string}
                  author={post.author as string}
                  linkElement={
                    <Link
                      href={post.slug as string}
                      aria-label={`Read more about ${post.title}`}
                      role="link"
                      className="linker"
                    ></Link>
                  }
                />
              </div>
            );
          case "EventsCard":
            return (
              <div key={`eventCard-${i}`} data-cursor={slider ? "drag" : ""}>
                <EventsCard
                  date={formatDate(post?.acf?.date as string, "DATE_SHORT")}
                  category={categoryName}
                  imageElement={imageElement}
                  title={post.title as string}
                  linkElement={
                    <Link
                      href={post.slug as string}
                      aria-label={`Read more about ${post.title}`}
                      role="link"
                      className="linker"
                    ></Link>
                  }
                />
              </div>
            );
          case "NewsCard":
            return (
              <div key={`newsCard-${i}`} data-cursor={slider ? "drag" : ""}>
                <NewsCard
                  variant={variant}
                  date={formatDate(post?.date as string, "DATE_SHORT")}
                  category={categoryName}
                  imageElement={imageElement}
                  title={post.title as string}
                  description={post.excerpt as string}
                  linkElement={
                    <Link
                      href={post.slug as string}
                      aria-label={`Read more about ${post.title}`}
                      role="link"
                      className="linker"
                    ></Link>
                  }
                />
              </div>
            );
          case "ProductCard":
            return (
              <ProductCard
                key={`productCard-${i}`}
                imageElement={<DisplayImage
                  url={post?.image as string}
                  alt={post?.title ? (post?.title as string) : ""}
                  width={100}
                  height={50}
                  fill={false}
                />}
                title={post.title as string}
                className="mb-4"
                linkElement={
                  <Link
                    href={"/product/"}
                    aria-label={`Read more about ${post.title}`}
                    role="link"
                    className="linker"
                  ></Link>
                }
              />
            );
          case "CareersCard":
            return (
              <CareersCard
                key={`careersCard-${i}`}
                title={post.title as string}
                description={post.excerpt as string}
                linkElement={
                  <Link
                    href={post.slug as string}
                    aria-label={`Read more about ${post.title}`}
                  >
                    <Button
                      variant="secondary"
                      rightIcon={<Icon icon="icon-arrow-right" />}
                    >
                      {state?.dict?.custom?.applyNow}
                    </Button>
                  </Link>
                }
                className="mb-4"
              />
            );
        }
      })
    }
  }, [filteredPosts, component])

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      setFilters(values as IFilters);
    },
    onReset: () => {
      setFilters(null);
    },
  });

  return (
    <Row fluid={true} className={className}>
      {(title || (showFilters && activeFilters.length)) && (
        <Column className="mb-6">
          <Flex align="center">
            {title && (
              <div className="flex-shrink-1">
                <Heading tag="h3" size="h4">
                  {title}
                </Heading>
              </div>
            )}

            {showFilters && activeFilters.length > 0 && (
              <div className="flex-grow-1">
                <Flex align="center" justify="center" gap="1rem">
                  <StyledFilter
                    variant="dark"
                    hover={true}
                    active={!filters?.category || filters?.category === "all"}
                  >
                    <input
                      type="radio"
                      name="category"
                      id={"all"}
                      value={""}
                      checked={!filters?.category || filters?.category === "all"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.handleSubmit();
                      }}
                    ></input>
                    <p className="captions fw-bold">{state?.dict?.common?.all}</p>
                  </StyledFilter>
                  {grid &&
                    displayFilters &&
                    displayFilters.map((category: TaxonomyTerms, i: number) => {
                      return (
                        <StyledFilter
                          key={`${category.slug}`}
                          variant="dark"
                          hover={true}
                          active={category.slug === filters?.category}
                        >
                          <input
                            type="radio"
                            name="category"
                            id={category.slug}
                            value={category.slug}
                            checked={category.slug === filters?.category}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.handleSubmit();
                            }}
                          ></input>
                          <p className="captions fw-bold">{category.name as string}</p>
                        </StyledFilter>
                      );
                    })}
                </Flex>
              </div>
            )}
          </Flex>
        </Column>
      )}
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Suspense
          fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
        >
          {cachedContent ? (
            <>
              {((isMobile && sliderMobile) || slider) ? (
                <div className="w-100">
                  <Slider
                    arrows={true}
                    dots={true}
                    slidesToShow={per_line}
                    infinite={true}
                    autoplay={sliderMobile ? false : true}
                    mobileOnly={sliderMobile}
                    mediaQueries={{
                      900: {
                        slidesToShow: per_line > 1 ? per_line - 1 : per_line,
                        autoplay: true
                      },
                      500: {
                        slidesToShow: 1
                      }
                    }}
                  >
                    {cachedContent.map((block) => {
                      return block
                    })}
                  </Slider>
                </div>
              ) : (
                <>
                  {cachedContent.map((block, i) => {
                    return <Column key={`column-${component}-${i}`} sm={12} md={colSize}>{block}</Column>
                  })}
                </>
              )}

              {showPagination &&
                <Column sm={12} className="pt-6 pb-10">
                  <PaginationStepper
                    onPageChange={(e) => {
                      setFilters({
                        ...filters,
                        page: e,
                      } as IFilters);
                    }}
                    onChangePageSize={(pageSize) => {
                      // setFilters('s', pageSize);
                    }}
                    pageSize={per_page || 0}
                    totalItems={totalPosts || 0}
                    currentPage={filters?.page || 1}
                  />
                </Column>
              }
            </>
          ) : (
            <Column className="pt-7 pb-5">
              <EmptyState
                variant={"light"}
                label={state?.dict?.common?.noResults}
              ></EmptyState>
            </Column>
          )}
        </Suspense>
      )}
    </Row>
  );
}
